import React from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { appDomain, pitcheroDomain } from 'lib/env';
import clubUrl from 'lib/routing/club-url';
import { getCurrentClub } from 'store/clubs/selectors';
import TokenRedirectLink from './index';

const TokenRedirectRoute = ({ club, currentClub, children, route, params }) => {
  const clubLinkingTo = club || currentClub;
  const url = clubUrl({ folder: clubLinkingTo.folder }, route, params);
  return (
    <TokenRedirectLink href={url.replace(appDomain(), pitcheroDomain())}>
      {children}
    </TokenRedirectLink>
  );
};

TokenRedirectRoute.defaultProps = {
  club: null,
  params: {},
};

TokenRedirectRoute.propTypes = {
  club: PropTypes.shape({
    externalDomain: PropTypes.string,
    folder: PropTypes.string.isRequired,
  }),
  currentClub: PropTypes.shape({
    externalDomain: PropTypes.string,
    folder: PropTypes.string.isRequired,
  }).isRequired,
  children: PropTypes.oneOfType([PropTypes.node, PropTypes.arrayOf(PropTypes.node)]).isRequired,
  route: PropTypes.string.isRequired,
  params: PropTypes.shape(),
};

const mapStateToProps = (state) => ({
  currentClub: getCurrentClub(state),
});

export default connect(mapStateToProps)(TokenRedirectRoute);
