import {
  LOGIN_PANEL_CLOSE,
  LOGIN_PANEL_OPEN,
  LOGIN_REQUEST,
  LOGIN_SUCCESS,
  LOGIN_FAILURE,
  PASSWORD_RESET_REQUEST,
  PASSWORD_RESET_SUCCESS,
  PASSWORD_RESET_FAILURE,
} from 'store/action-types';

export const attemptLogin = (email, password) => async (dispatch) => {
  try {
    dispatch({ type: LOGIN_REQUEST });
    const { makeIsomorphicApiRequest } = await import('lib/api-proxy');
    const response = await makeIsomorphicApiRequest('v2/auth/jwt', {
      method: 'POST',
      headers: {
        Accept: 'application/json',
        'Content-Type': 'application/json',
      },
      body: JSON.stringify({ username: email, password }),
    });

    // If we're 200 or 201, successful request coming through
    if (response.status === 200 || response.status === 201) {
      await response.json();
      dispatch({ type: LOGIN_SUCCESS, payload: response });
    } else {
      dispatch({ type: LOGIN_FAILURE, payload: response });
    }
  } catch (error) {
    dispatch({ type: LOGIN_FAILURE });
  }
};

export const attemptTokenLogin = (token) => async (dispatch) => {
  dispatch({ type: LOGIN_REQUEST });
  const { makeIsomorphicApiRequest } = await import('lib/api-proxy');
  const response = await makeIsomorphicApiRequest('v2/auth/token-login', {
    method: 'POST',
    headers: {
      Accept: 'application/json',
      'Content-Type': 'application/json',
    },
    body: JSON.stringify({ token }),
  });

  // If we're 200 or 201, successful request coming through
  if (response.status === 200 || response.status === 201) {
    try {
      await response.json();
      dispatch({ type: LOGIN_SUCCESS });
    } catch {
      dispatch({ type: LOGIN_FAILURE });
    }
  } else {
    dispatch({ type: LOGIN_FAILURE });
  }
};

export const openLoginPanel = () => (dispatch) => {
  dispatch({ type: LOGIN_PANEL_OPEN });
};

export const closeLoginPanel = () => (dispatch) => {
  dispatch({ type: LOGIN_PANEL_CLOSE });
};

export const resetPassword = (email) => async (dispatch) => {
  dispatch({ type: PASSWORD_RESET_REQUEST });

  const { makeIsomorphicApiRequest } = await import('lib/api-proxy');
  const response = await makeIsomorphicApiRequest('v2/auth/password-reset', {
    method: 'POST',
    headers: {
      Accept: 'application/json',
      'Content-Type': 'application/json',
    },
    body: JSON.stringify({ email }),
  });

  // 201 and 200 codes are valid
  const validStatusCodes = [201, 200];

  if (validStatusCodes.includes(response.status)) {
    dispatch({ type: PASSWORD_RESET_SUCCESS });
  } else {
    dispatch({ type: PASSWORD_RESET_FAILURE, payload: response });
  }
};
